/* Container styles */
.Blogsearch-container {
  display: flex;
  position: relative;
  width: 90%;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

/* Input field styles */
.Blogsearch-input {
  width: 750px;
  border: 1px solid #ccc;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding-left: 10px;
  font-size: xx-large;
}

.Blogsearch-input.visible {
  opacity: 1;
}

/* Search icon styles */
.BlogsearchIcon {
  font-size: xx-large;
  cursor: pointer;
  z-index: 999;
  color: darkblue;
}

.BlogsearchIcon:hover {
  color: darkmagenta;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .Blogsearch-container {
    display: flex;
    position: relative;
    width: 90%;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between;
  }

  /* Input field styles */
  .Blogsearch-input {
    width: 250%;
    border: 1px solid #ccc;
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.5s ease;
    padding-left: 10px;
    font-size: xx-large;
  }

  /* Search icon styles */
  .BlogsearchIcon {
    font-size: large;
    cursor: pointer;
    z-index: 999;
    opacity: 0;
    color: darkblue;
  }

  .BlogsearchIcon:hover {
    color: darkmagenta;
  }

  /* .Blogsearch-input {
    width: 200px;
    font-size: x-large; 
  }

  .BlogsearchIcon {
    padding-left: 20px;
  } */
}
