/* Your CSS or Sass file */
.loading-spinner {
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
z-index: 999;
}

.loading-spinnerr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}


.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoloder {
  display: flex;
  justify-content: center;
  width: 70px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  align-items: center;
  cursor: auto;
}

.lodingInLoding {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lodingLoding {
  color:rgb(38, 38, 207);
  font-size: xx-large;
}

.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #8cf602;
  --fifth-color: #f32121;
  --sixth-color: #f602a5;
  --seventh-color: #f35d21;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loading span {
  width: 5px;
  height: 70px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
  pointer-events: none;
}

.loading:enabled {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

.loading span:nth-child(6) {
  background: var(--sixth-color);
  animation-delay: -0.4s;
}

.loading span:nth-child(7) {
  background: var(--seventh-color);
  animation-delay: -0.3s;
}

@keyframes scale {

  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}