.validate-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
background: url(/public/images/validate.jpg);
  background-blend-mode: multiply;
  object-fit: cover;
  backface-visibility: hidden;
    background-position:center;
    background-size: cover;
  }
  
  .validate-emailpage-center {
    width: 60%;
  }
  
  .validate-emailcard {
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100%;
    overflow: hidden;
  }

  .validate-emailcard-body {
    transform: scale(1.1);
  }
  
  .validate-emailcard-body {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .validate-emailtext-center {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 30px;
    text-align: center;
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .validate-emailmb-0 Link
  {
    color: lightpink;
  }

  .validate-emailprimary-color {
    color: #007bff;
  }
  
  .validate-emailcolor-green {
    color: #28a745;
  }
  
  .validate-emailcolor-red {
    color: #dc3545;
  }
  
  .validate-emailfa-solid {
    font-family: 'Font Awesome 5 Pro';
  }
  
  .validate-emailfa-check {
    animation: fadeIn 1s ease-in-out;
  }
  
  .validate-emailfa-xmark {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Hover effect */
  .validate-emailfa-check:hover,
  .validate-emailfa-xmark:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Adjustments for mobile responsiveness */
  @media only screen and (max-width: 768px) {
    .validate-emailpage-center {
      max-width: 100%;
    }
  }
  