/* Set default styles for the editor container */
.editor-container {
  position: relative;
  background-color: #fff;
  color: #333;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-top: 10px;
}

.editor-container:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Style images to prevent text overlap */
.ql-editor img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  clear: both;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Style code blocks */
.ql-syntax {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

/* Style the toolbar */
.ql-toolbar {
  position: sticky;
  top: 0px;
  z-index: 1;
  border-bottom: 1px solid #ccc;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Style the code block button */
.ql-code-block {
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 4px;
  transition: background-color 0.3s ease;
}

.ql-code-block:hover {
  background-color: #444;
}

/* Hide toolbar when not in edit mode */
.hide-toolbar .ql-toolbar {
  display: none;
}

/* Set styles for the font dropdown */
.ql-font {
  font-family: "Arial, sans-serif";
}

/* Set styles for the size dropdown */
.ql-size {
  font-size: 12px;
}

/* Set styles for color and background color */
.ql-color,
.ql-background {
  border: none;
  padding: 0;
}

/* Style the editor content area */
.ql-editor {
  flex: 1;
  font-family: "Arial, sans-serif";
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  padding: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  box-sizing: border-box;
  background-color: #fff;
}

/* Additional styles for specific elements */
.ql-editor h1 {
  font-size: 24px;
  color: #333;
}

.ql-editor h2 {
  font-size: 20px;
  color: #333;
}

.ql-editor h3 {
  font-size: 18px;
  color: #333;
}

/* Slider Controls */
.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.slider-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  margin: 0 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.slider-buttons button:hover {
  background-color: #0056b3;
}
