/* signUp.css */

.sign-up {
  display: flex;
  align-items: left;
  justify-content: left;
  height: 91vh;
  width: 100%;
  background-image: url(/public/images/signup.jpg);
  background-size: 50%;
  background-position-x: 80%;
  background-position-y: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  animation: adeIn 1s ease-in-out;
}

@keyframes adeIn {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.Signpage-center {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  max-height: 120%;
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.signUph1 {
  text-align: center;
  color: #333333;
}

.lbl {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555555;
}

.input-icon {
  position: relative;
}

.user-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #777777;
}

.Signupinput-ctrl {
  width: calc(100% - 26px);
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #dddddd;
  color: #333333;
  transition: border-color 0.3s ease;
}

.Signupinput-ctrl.error-class {
  border-color: #ff4d4f;
}

.Signupinput-ctrl.noerror-class {
  border-color: #dddddd;
}

.signUp-btn {
  text-align: center;
}

.signUp-btn button {
  background: linear-gradient(to right, #4caf50, #45a049);
  color: #ffffff;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.signUp-btn button:hover {
  background: linear-gradient(to right, #45a049, #4caf50);
}

.login-link {
  display: inline-flex;
  text-align: center;
  margin-top: 15px;
}

.login-link p {
  margin-left: 10px;
  color: #888888;
}

.login-link .highlighted {
  color: #4caf50;
  cursor: pointer;
}

.login-link .highlighted:hover {
  text-decoration: underline;
}

.error-message {
  z-index: 9999;
}
