/* resetPassword.css */

.reset-pswd {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #74ebd5, #acb6e5);
}

.page-center {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.rpWh1 {
  text-align: center;
  color: #333333;
}

.lbl {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555555;
}

.input-icon {
  position: relative;
}

.input-ctrl {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333333;
  background-color: #ecf0f1;
  transition: background-color 0.3s ease;
}

.input-ctrl:focus {
  outline: none;
  background-color: #d5d8dc;
}

.input-ctrl.error-class {
  border-color: #ff4d4f;
}

.input-ctrl.noerror-class {
  border-color: #dddddd;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  background: linear-gradient(to right, #4caf50, #45a049);
  color: #ffffff;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-btn button:hover {
  background: linear-gradient(to right, #45a049, #4caf50);
}

.sign-up-link {
  text-align: center;
  margin-top: 15px;
}

.sign-up-link p {
  margin: 0;
  color: #888888;
}

.sign-up-link .highlighted {
  color: #3498db;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sign-up-link .highlighted:hover {
  color: #e74c3c;
  text-decoration: underline;
}
