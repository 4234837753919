/* LikeButton Component */
.LikeButton {
  display: flex;
  margin-top: 10px;
}

.LikeButton button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.LikeButton button span.liked {
  color: #009b40; /* Change the color to your desired color */
  font-size: 34px; /* Change the font size to your desired size */
}

.LikeButton button span.notLiked {
  color: darkorange; /* Change the color to your desired color when not liked */
  font-size: 26px; /* Change the font size to your desired size when not liked */
}

/* .isLiked {
  color: darkorange;
  font-size: larger;
} */

/* .isLiked:hover {
  color: #0004ff;
  font-size: x-large;
} */

/* Fireworks Animation */
@keyframes fireworks-position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes fireworks-gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes fireworks-bang {
  to {
    box-shadow: 114px -107.3333333333px #8800ff, 212px -166.3333333333px #a600ff,
      197px -6.3333333333px #ff006a, 179px -329.3333333333px #3300ff,
      -167px -262.3333333333px #ff0062, 233px 65.6666666667px #ff008c,
      81px 42.6666666667px #0051ff, -13px 54.6666666667px #00ff2b,
      -60px -183.3333333333px #0900ff, 127px -259.3333333333px #ff00e6,
      117px -122.3333333333px #00b7ff, 95px 20.6666666667px #ff8000,
      115px 1.6666666667px #0004ff, -160px -328.3333333333px #00ff40,
      69px -242.3333333333px #000dff, -208px -230.3333333333px #ff0400,
      30px -15.3333333333px #e6ff00, 235px -15.3333333333px #fb00ff,
      80px -232.3333333333px #d5ff00, 175px -173.3333333333px #00ff3c,
      -187px -176.3333333333px #aaff00, 4px 26.6666666667px #ff6f00,
      227px -106.3333333333px #ff0099, 119px 17.6666666667px #00ffd5,
      -102px 4.6666666667px #ff0088, -16px -4.3333333333px #00fff7,
      -201px -310.3333333333px #00ffdd, 64px -181.3333333333px #f700ff,
      -234px -15.3333333333px #00fffb, -184px -263.3333333333px #aa00ff,
      96px -303.3333333333px #0037ff, -139px 10.6666666667px #0026ff,
      25px -205.3333333333px #00ff2b, -129px -322.3333333333px #40ff00,
      -235px -187.3333333333px #26ff00, -136px -237.3333333333px #0091ff,
      -82px -321.3333333333px #6a00ff, 7px -267.3333333333px #ff00c8,
      -155px 30.6666666667px #0059ff, -85px -73.3333333333px #6a00ff,
      60px -199.3333333333px #55ff00, -9px -289.3333333333px #00ffaa,
      -208px -167.3333333333px #00ff80, -13px -299.3333333333px #ff0004,
      179px -164.3333333333px #ff0044, -112px 12.6666666667px #0051ff,
      -209px -125.3333333333px #ff00bb, 14px -101.3333333333px #00ff95,
      -184px -292.3333333333px #ff0099, -26px -168.3333333333px #09ff00,
      129px -67.3333333333px #0084ff, -17px -23.3333333333px #0059ff,
      129px 34.6666666667px #7300ff, 35px -24.3333333333px #ffd900,
      -12px -297.3333333333px #ff8400, 129px -156.3333333333px #0dff00,
      157px -29.3333333333px #1a00ff, -221px 6.6666666667px #ff0062,
      0px -311.3333333333px #ff006a, 155px 50.6666666667px #00ffaa,
      -71px -318.3333333333px #0073ff;
  }
}

.LikeButton button:hover {
  background-color: transparent;
}

.LikeButton button span {
  transition: color 0.3s ease, transform 0.9s ease; /* Add a smooth transition effect */
}

/* .LikeButton button:hover span {
  color: #009b40; 
} */

.LikeButton button span.liked {
  color: #009b40;
  animation: thumbPulse 0.9s; /* Add a pulse animation for liked state */
  font-size: xx-large;
  font-weight: 300;
  /* animation: 1s fireworks-bang ease-out forwards,
    1s fireworks-gravity ease-in forwards, 5s fireworks-position linear forwards; */
  animation-duration: 1.25s, 1.25s, 6.25s;
}

.LikeButton button span.notLiked {
  color: orangered; /* Change to the color you want for liked state */
  font-size: xx-large;
  font-weight: 300;
  /* animation: 1s fireworks-bang ease-out forwards,
    1s fireworks-gravity ease-in forwards, 5s fireworks-position linear forwards; */
}

@keyframes thumbPulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1.2);
  }
}

.personLikeThis {
  color: darkgoldenrod;
  font-weight: 200;
  font-size: medium;
}

.likeNo {
  color: #035a94;
  font-size: 20px;
  padding-left: 10px;
}

.likeperson {
  color: darkgoldenrod;
}

.modalHeader {
  color: #035a94;
  font-size: medium;
  text-align: center;
  font-weight: 700;
}

.modalLikers {
  color: crimson;
  box-sizing: border-box;
  border-style: inset;
  border-color: blueviolet;
  padding-top: 1%;
  padding-left: 20px;
}

/* CommentSection Component */
.CommentSection {
  box-shadow: #fff;
  border: white;
}

.CommentSection h3 {
  color: darkgreen;
  box-sizing: border-box;
  border-bottom: solid;
  border-color: #2ecc71;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
}

.CommentSection textarea {
  width: 100%;
  margin-bottom: 8px;
  padding-left: 5px;
}

.CommentSection button {
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

/* Inside your CSS file */
.commentContainer {
  display: flex;
  text-align: left;
  box-sizing: border-box;
  border-bottom: solid;
  border-color: #2ecc71;
  padding-left: 3%;
}

.commentText {
  flex: 1;
  padding-left: 5px;
  margin-top: 5px;
  color: rgb(141, 0, 163);
  max-width: 78%;
  overflow: hidden;
}

.commentUser {
  padding-right: 5px;
  color: rgb(221, 122, 0);
  font-size: 12px;
}

.commentInputSub {
  display: flex;
}

.loadMore {
  background-color: transparent;
  margin-left: 35%;
  margin-top: 10px;
}

.loadMore button {
  background-color: transparent;
  border-style: groove;
  border-color: #035a94;
  padding-right: 15px;
  color: rgb(133, 209, 1);
  font-size: medium;
  font-weight: 500;
}

.commentTimestamp {
  color: darkred;
}
