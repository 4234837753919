/* Add this CSS to your existing stylesheet or create a new one */

.reset-pwd-action {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 90vh;
  background-image: url(/public/images/resetPass.jpg);
  background-position-x: 10%;
  background-position-y: 30%;
  background-size: 50%;
  background-repeat: no-repeat;
  overflow-y: auto;
  animation: fadIReset 1s ease-out;
}

@keyframes fadIReset {
  from {
    opacity: 0;
    transform: translateY(300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-center {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  animation: fadeReset 1s ease-out;
}

@keyframes fadeReset {
  from {
    opacity: 0;
    transform: translateY(-700px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.crph1 {
  text-align: center;
  color: #333;
}

.user-name,
.pswd,
.cnfm-pswd {
  margin-bottom: 15px;
}

.lbl {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.input-icon {
  position: relative;
}

.input-ctrl {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error-class {
  border-color: #ff0000;
}

.reset-btn {
  text-align: center;
}

.reset-btn button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reset-btn button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login-link {
  text-align: center;
  margin-top: 15px;
}

.highlighted {
  color: #007bff;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
