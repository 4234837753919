/* login.css */

.login {
  display: flex;
  align-items: right;
  justify-content: right;
  height: 100vh;
  width: 100%;
  background-image: url(/public/images/login.jpg);
  background-size: 50%;
  background-position-x: 20%;
  background-position-y: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  animation: fadI 1s ease-out;
}

@keyframes fadI {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.Loginpage-center {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  animation: fadeI 1s ease-out;
}

@keyframes fadeI {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.loginH1 {
  text-align: center;
  color: #333333;
}

.lbl {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555555;
}

.input-icon {
  display: inline;
  position: relative;
}

.user-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #3498db;
  transition: color 0.3s ease;
}

.input-ctrl {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333333;
  background-color: #ecf0f1;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  padding-right: 30px !important;
}

.input-ctrl:focus {
  outline: none;
  background-color: #d5d8dc;
}

.input-ctrl.error-class {
  border-color: #ff4d4f;
}

.input-ctrl.noerror-class {
  border-color: #dddddd;
}

.frgt-pswd {
  text-align: right;
  margin-bottom: 15px;
  color: #3498db;
  cursor: pointer;
  transition: color 0.3s ease;
}

.frgt-pswd:hover {
  color: #e74c3c;
  text-decoration: underline;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  background: linear-gradient(to right, #4caf50, #45a049);
  color: #ffffff;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-btn button:hover {
  background: linear-gradient(to right, #45a049, #4caf50);
}

.sign-up-link {
  text-align: center;
  margin-top: 15px;
}

.sign-up-link p {
  margin: 0;
  color: #888888;
}

.sign-up-link .highlighted {
  color: #3498db;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sign-up-link .highlighted:hover {
  color: #e74c3c;
  text-decoration: underline;
}

/* Additional Styling for Icons */
.user-icon,
.lock-icon {
  font-size: 20px;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
}
