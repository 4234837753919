/* Blog Container */
.blog-cont {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}

.selectblogM {
  height: 100vh;
  overflow-y: auto;
  background: linear-gradient(
      43deg,
      rgb(65, 88, 208) 0%,
      rgb(200, 80, 192) 46%,
      rgb(255, 204, 112) 100%
    )
    fixed; /* Fixed background */
  background-size: cover;
}

.blog-container {
  font-family: Arial, sans-serif;
  color: #61dafb; /* Text color */
  text-align: center;
  padding-bottom: 30px;
  background: linear-gradient(
      43deg,
      rgb(65, 88, 208) 0%,
      rgb(200, 80, 192) 46%,
      rgb(255, 204, 112) 100%
    )
    fixed; /* Fixed background */
  background-size: cover;
  height: 100vh;
}

/* Blog Card */
.blog-slider1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(
      43deg,
      rgb(65, 88, 208) 0%,
      rgb(200, 80, 192) 46%,
      rgb(255, 204, 112) 100%
    )
    fixed; /* Fixed background */
  background-size: cover;
  padding-bottom: 30px;
}

.MainBlogCard {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  height: 300px;
  width: 190px;
  min-width: 500px;
  padding: 1rem;
  border-radius: 16px;
  background: #17141d;
  box-shadow: -1rem 0 3rem #00000067;
  transition: 0.2s;
  font-family: "Inter", sans-serif;
  margin-left: 80px;
  cursor: pointer;
  margin-top: 30px;
  align-items: center; /* comment krna h jb like vala option dalna h tb */
  justify-content: center; /* comment krna h jb like vala option dalna h tb */
}

.blog-card {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 10px;
  color: #7a7a8c;
}

.blogAuthorDate {
  display: flex;
  flex-direction: row;
}

.Blogauthor {
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
}

.Blogauthor-name {
  color: #7a7a8c;
}

.Blogauthor-name-prefix {
  color: #e52e71;
  font-weight: 600;
  margin-right: 250px;
}

.author-container {
  margin-right: 250px;
}

.Blogauthor-avatar span {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f2f2f2;
  margin: 16px 10px;
}

.half-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: #ff8a00;
  stroke-width: 8;
  stroke-linecap: round;
}

.blog-card p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.blog-card h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  color: blue;
}

.blog-card .title {
  font-size: 25px;
  margin: 0.25rem 0 auto;
  cursor: pointer;
  font-family: "Arial Black", sans-serif;
}

.blog-card .title:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MainBlogCard:hover {
  transform: scale(1.05);
}

.Bloglike-comment-info {
  margin: 1rem 0 2rem;
  padding: 0.5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.SearchBlogcardtags span {
  font-style: normal;
  font-weight: 700;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: 0.66rem;
  border: 3px solid #28242f;
  border-radius: 2rem;
  padding: 0.2rem 0.85rem 0.25rem;
  position: relative;
}

.SearchBlogcardtags span:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-clip: text;
  border-color: white;
}

.blog-card-selected {
  position: absolute;
  margin-bottom: 30px;
  margin-left: 150px;
}

/* Author and Date */
.SelectBlogauthor-and-date {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #0075f1; /* Lighter text color */
  margin-left: 50px;
}

.selactBLogTitle {
  width: 100%;
  color: darkblue; /* Text color */
  font-size: 24px; /* Increased font size */
  margin-top: 0px;
  margin-left: 100px;
}

.SelectBlogauthor-container {
  width: 20%;
  font-size: larger;
}

.SelectBlogdate-container {
  color: #0075f1;
  font-weight: 500;
  margin-left: 50px;
}

.backBlogButtons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.slider1-buttons {
  width: 100%;
  position: fixed;
  display: table-row;
  align-items: right;
  z-index: 997;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.PreButton,
.NextButton,
.createBlogButton {
  margin-right: 0px;
}

.searchButton {
  margin-left: 20%;
}

.createBlogButton {
  margin-left: 141%;
}

.crateBlogLink {
  background-color: transparent;
  border: none;
  font-size: large;
}

.crateBlogLink:hover {
  background-color: transparent !important;
}

.PluseButton {
  color: darkblue;
  font-size: larger;
}

.PluseButton:hover {
  color: darkmagenta;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.createBlogLink:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.blog-card-selected {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .blog-cont {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .MainBlogCard {
    height: 250px; /* Increased height for medium screens */
    min-width: 300px; /* Maximum width for medium screens */
    margin-left: 0px;
  }

  .MainBlogCard:first-child {
    margin-top: 90px;
  }

  .blogAuthorDate {
    display: flex;
    flex-direction: column-reverse;
  }

  .Blogauthor {
    position: relative;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    justify-content: center;
  }

  .Blogauthor-name {
    width: 50px;
    color: #7a7a8c;
  }

  .Blogauthor-name-prefix {
    color: #e52e71;
    font-weight: 600;
  }

  .author-container {
    margin-right: 250px;
  }

  .Blogauthor-avatar span {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f2f2f2;
    margin: 16px 10px;
  }

  .half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: #ff8a00;
    stroke-width: 8;
    stroke-linecap: round;
  }

  .blog-card p {
    font-size: 14px;
    margin: 0 0 1rem;
    color: #7a7a8c;
  }

  .blog-card h2 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: blue;
  }

  .blog-card .title {
    font-size: 25px;
    margin: 0.25rem 0 auto;
    cursor: pointer;
    font-family: "Arial Black", sans-serif;
  }

  .blog-card .title:hover {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    text-shadow: none;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .MainBlogCard:hover {
    transform: scale(1.05);
  }

  .slider1-buttons {
    position: fixed;
    display: table-row;
    align-items: right;
    z-index: 1;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 50%;
  }

  .searchButton {
    margin-left: 40%;
  }
}
/* CSS for the div with class "no-blogs-message" */
.no-blogs-message {
  position: fixed; /* Making the div position fixed */
  top: 50%; /* Placing it 50% from the top of the screen */
  left: 50%; /* Placing it 50% from the left of the screen */
  transform: translate(-50%, -50%); /* Centering the div */
  max-width: 600px; /* Limiting the width of the div */
  padding: 20px; /* Adding some padding */
  background-color: #f0f0f0; /* Setting background color */
  border-radius: 10px; /* Adding border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
  color: black;
}

/* CSS for the paragraph inside the div */
.no-blogs-message p {
  margin-bottom: 10px; /* Adding some bottom margin to separate paragraphs */
}

/* CSS for the anchor tag inside the paragraph */
.no-blogs-message a {
  color: black; /* Setting link color */
  text-decoration: none; /* Removing underline */
  font-weight: bold; /* Making it bold */
}

/* CSS for hover effect on the anchor tag */
.no-blogs-message a:hover {
  text-decoration: underline; /* Underlining on hover */
}
