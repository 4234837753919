/* SelectedBlog.css */
.SelectBlog-card-selectedM {
  height: 87vh;
  overflow-y: auto;
}

.SelectBlog-card-selected {
  margin: 20px auto;
  padding: 20px;
}

.Selectblogauthor-and-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3498db;
  margin-left: 30px;
}

.Selectblogauthor-container {
  font-weight: bold;
}

.Selectblogdate-container {
  font-style: italic;
  margin-right: 30px;
  font-weight: bold;
}

.selectblogtitle {
  width: 100%;
  display: flex;
}

.selactbLogTitle {
  margin: auto;
  font-size: 24px;
  color: darkblue;
  align-items: center;
}

.selectBlogEdit,
.selectBlogEditinEdit {
  margin-bottom: 20px;
}

.selectBlogButtons {
  display: flex;
  justify-content: space-evenly;
}

/* Style for the Editor component */
/* You may need to adjust this based on your Editor component's structure and styles */
.react-quill {
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Style for the LikeButton and CommentSection components */
.SelectBloglike-comment-container {
  display: flex;
  flex-direction: column;
}

.blog-card-selected {
  /* Add your existing styles for the main container */
  transition: transform 0.3s ease-in-out;
}

.selectBlogEdit,
.selectBlogEditinEdit {
  /* Add your existing styles for the blog editor container */
  transition: opacity 0.3s ease-in-out;
}

/* Add styles for the buttons */
/* .selectBlogButtons button {
  margin-right: 80px;
  margin-left: 80px;
  padding: 8px 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.selectBlogButtons button:hover {
  background-color: #2980b9;
} */

/* Add styles for the Editor component */
.editor {
  /* Customize editor styles as needed */
  transition: border 0.3s ease-in-out;
}

.editor:hover {
  border: 1px solid #3498db;
}

.selectBlogButtons {
  display: inline;
  justify-content: space-between;
}

.deleteBtn {
  margin-left: 10px;
  background-color: #ff6868;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.editBtn {
  margin-top: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}

.deleteBtn:hover {
  background-color: #a04545;
}

.editBtn:hover {
  background-color: #037a09;
}

.backBtn {
  margin-left: 10px;
  background-color: #0075f1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.backBtn:hover {
  background-color: #0354ac;
}

.saveBtn {
  margin-left: 10px;
  background-color: #e52e71;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.saveBtn:hover {
  background-color: #9c043c;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .SelectBlog-card-selectedM {
    max-width: 100%;
    min-width: 100%;

    height: 87vh;
    overflow-y: auto;
  }

  .SelectBlog-card-selected {
    width: 100%;
    padding: 10px; /* Reduce padding for smaller screens */
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    margin-left: 0px;
  }

  .selactbLogTitle {
    font-size: 20px; /* Decrease title font size */
  }

  .Selectblogauthor-and-date {
    flex-direction: column; /* Stack author and date vertically */
    align-items: flex-start; /* Align items to start */
  }

  .Selectblogauthor-container,
  .Selectblogdate-container {
    text-align: left; /* Align text to left for smaller screens */
  }
}
